<template>
  <transition name="slide-fade-enter" appear>
    <div class="d-panel-body d-flex justify-content-center p-0">
      <div class="d-flex">
        <div class="w-100 h-100 d-flex align-items-center justify-content-center" style="border-radius: 10px;">
          <div class="display-area">
            <div class="p-3 pt-sm-5 px-sm-5 pb-sm-4" style="max-width: 100vw">
              <template v-if="showPasswordResetScreen">
                <div class="d-flex justify-content-center mb-2 flex-wrap">
                  <h3 v-if="domainConfiguration.logo" class="d-flex justify-content-center w-100 mb-2">
                    <img style="height: 36px; border-radius: 10px" :src="domainConfiguration.logo">
                  </h3>
                  <PasswordResetDisplay v-model="showPasswordResetScreen" :domainConfiguration="domainConfiguration" :email="emailInput" />
                </div>
              </template>
              <template v-else-if="showVerifyEmailScreen || showFallbackHandling === 'Email'">
                <div class="d-flex justify-content-center mb-2 flex-wrap">
                  <h3 v-if="domainConfiguration.logo" class="d-flex justify-content-center w-100 mb-2">
                    <img style="height: 36px; border-radius: 10px" :src="domainConfiguration.logo">
                  </h3>
                  <VerifyEmail :email="emailInput" :domainConfiguration="domainConfiguration"
                    :goToEmailToVerify="showFallbackHandling === 'Email'" />
                </div>
              </template>

              <template v-else>
                <!-- Wrap everything in one div: This fixes transition incorrectly broken by putting everything in a div. -->
                <div>
                  <div class="d-flex justify-content-center mb-2 flex-wrap">
                    <h3 v-if="domainConfiguration.logo" class="d-flex justify-content-center w-100 mb-2">
                      <img style="height: 36px; border-radius: 10px" :src="domainConfiguration.logo">
                    </h3>
                    <h3 class="d-flex justify-content-center w-100 mb-2"><span>{{ signup ? 'Sign Up' : domainConfiguration.title || 'Sign In' }}</span></h3>
                    <small v-if="!signup">Don't have an account? <strong class="pointer"
                      @click="() => { signup = true; showValidations = false; showFallbackHandling = null; lastSelectedConnectionId = null; }">
                      Sign up</strong></small>
                    <small v-else>Return to <strong class="support-link pointer"
                      @click="() => { signup = false; showValidations = false; showFallbackHandling = null; lastSelectedConnectionId = null; }">
                      Sign in</strong></small>
                  </div>

                  <template v-if="!emailSsoInputBoxEnabled && !availableConnections.length">
                    Your authress account has no enabled connections for the hosted login UI.
                    <br>Configure one in the <a class="support-link pointer" href="https://authress.io/app/#/settings?focus=branding">Authress Management Portal</a>.
                  </template>

                  <template v-else-if="lastSelectedConnection">
                    <b-form-group style="margin-bottom: 0;" :state="displayErrors">
                      <div class="d-flex justify-content-center align-items-center">
                        <div style="font-size: 12px; padding-bottom: 0; text-align: center" class="mr-3">
                          <span>Last time you logged in with:</span>
                        </div>
                        <activity-button class="sign-in-button m-1" :action="() => validateDomainAndRedirectToLogin(lastSelectedConnection.connectionId)">
                          <div class="d-flex justify-content-center align-items-center">
                            <component :is="lastSelectedConnection.providerLogoComponent" />
                          </div>
                        </activity-button>
                      </div>

                      <template #invalid-feedback>
                        <LoginFormErrorMessage :validations="validations" :lastSelectedConnectionId="lastSelectedConnectionId" :emailInput="emailInput"
                          :supportUrl="supportUrl" :apiError="apiError" />
                      </template>
                    </b-form-group>

                    <template v-if="connectionsToDisplay.length">
                      <div class="d-inline-flex w-100 align-items-center">
                        <hr class="sign-in-with-line flex-grow-1">
                        <div class="sign-in-with flex-shrink-0">OR SIGN IN WITH</div>
                        <hr class="sign-in-with-line flex-grow-1">
                      </div>
                    </template>
                  </template>

                  <template v-else-if="emailSsoInputBoxEnabled">
                    <div class="d-flex justify-content-center">
                      <b-form-group :state="displayErrors" style="width: 300px; max-width: 95%; margin-top: 1rem; margin-bottom: 0;">
                        <template #invalid-feedback>
                          <LoginFormErrorMessage :validations="validations" :lastSelectedConnectionId="lastSelectedConnectionId" :emailInput="emailInput"
                            :supportUrl="supportUrl" :apiError="apiError" />
                        </template>
                        <template #label>
                          <div class="d-flex justify-content-between mb-1">
                            <div style="font-size: 12px; padding-bottom: 0;">EMAIL</div>
                            <BLink v-if="showFallbackHandling === 'Password' && emailInput" style="font-size: 11px" @click="{ showPasswordResetScreen = true; }">Forgot password</BLink>
                          </div>
                        </template>
                        <b-form-input class="email-input flex-shrink-0" maxlength="100" style="width: 300px; max-width: 100%" required placeholder="name@example.com"
                          autofocus autocomplete="on" name="email" id="email" :state="validations.emailInput"
                          v-model="emailInput"
                          @input="() => domainChanged()"
                          @keyup.enter="() => clickNextButton()" />

                        <div v-if="showFallbackHandling === 'Password'">
                          <template v-if="signup">
                            <b-form-input class="password-input flex-shrink-0 fs-exclude" maxlength="100" style="width: 300px; max-width: 100%" required
                            placeholder="•••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••"
                            autocomplete="new-password" type="password" name="password" id="password" :state="validations.passwordInput && validations.passwordIsLongEnough"
                            v-model="passwordInput"
                            @keyup.enter="() => clickNextButton()" />
                          </template>

                          <template v-else>
                            <b-form-input class="password-input flex-shrink-0 fs-exclude" maxlength="100" style="width: 300px; max-width: 100%" required
                            placeholder="•••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••"
                            autocomplete="current-password" type="password" name="password" id="password" :state="validations.passwordInput && validations.passwordIsLongEnough"
                            v-model="passwordInput"
                            @keyup.enter="() => clickNextButton()" />
                          </template>
                        </div>
                      </b-form-group>
                    </div>

                    <div class="d-flex justify-content-center mt-2">
                      <b-form-group style="width: 300px; max-width: 95%">
                        <activity-button ref="nextButton" class="btn-sm branded-primary-button pointer" style="width: 100% !important;"
                        :disabled="disableNextButton" :action="() => validateDomainAndRedirectToLogin()">
                          <span>Continue</span>
                        </activity-button>
                      </b-form-group>
                    </div>

                    <template v-if="connectionsToDisplay.length">
                      <template v-if="signup">
                        <div class="d-inline-flex w-100 align-items-center">
                          <hr class="sign-in-with-line flex-grow-1">
                          <div class="sign-in-with flex-shrink-0">OR SIGN UP WITH</div>
                          <hr class="sign-in-with-line flex-grow-1">
                        </div>
                      </template>

                      <template v-else>
                        <div class="d-inline-flex w-100 align-items-center">
                          <hr class="sign-in-with-line flex-grow-1">
                          <div class="sign-in-with flex-shrink-0">OR SIGN IN WITH</div>
                          <hr class="sign-in-with-line flex-grow-1">
                        </div>
                      </template>
                    </template>
                  </template>

                  <template v-if="connectionsToDisplay.length">
                    <div class="d-flex flex-wrap justify-content-center align-items-center">
                      <activity-button v-for="enabledConnection in connectionsToDisplay" :key="enabledConnection.connectionId" class="sign-in-button m-1"
                        :action="() => validateDomainAndRedirectToLogin(enabledConnection.connectionId)">
                        <div class="d-flex justify-content-center align-items-center">
                          <component :is="enabledConnection.providerLogoComponent" />
                        </div>
                      </activity-button>
                    </div>
                  </template>

                  <template v-if="emailSsoInputBoxEnabled && lastSelectedConnection">
                    <div>
                      <div class="d-inline-flex w-100 align-items-center">
                        <hr class="sign-in-with-line flex-grow-1">
                        <div class="sign-in-with flex-shrink-0">OR SIGN IN WITH SSO</div>
                        <hr class="sign-in-with-line flex-grow-1">
                      </div>
                      <div class="d-flex justify-content-center">

                        <activity-button class="sign-in-button sso m-1" :action="() => { lastSelectedConnectionId = null; showValidations = false; }">
                          <div class="d-flex justify-content-center align-items-center">
                            <fa icon="fa-envelope" class="mr-2" /> <div style="font-size: 14px;" class="d-flex align-items-center">Email SSO</div>
                          </div>
                        </activity-button>
                      </div>
                    </div>
                  </template>

                  <div class="d-flex justify-content-center mt-3 flex-wrap">
                    <span style="font-size: 12px"><b-link class="support-link pointer" :href="supportUrl" target="_blank">Contact Support</b-link></span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { BLink, BFormInput, BFormGroup } from 'bootstrap-vue';

import HttpClient from '../../clients/httpClient';
import logger from '../../clients/logger';
import jwtManager from '../../util/jwtManager.js';

import ActivityButton from '../../components/activityButton.vue';

import PasswordResetDisplay from './passwordResetDisplay.vue';
import VerifyEmail from './verifyEmail.vue';
import LoginFormErrorMessage from './loginFormErrorMessage.vue';

import AuthressLogo from '../logos/authressLogo.vue';
import ZohoLogo from '../logos/zohoLogo.vue';
import GoogleLogo from '../logos/googleLogo.vue';
import MicrosoftLogo from '../logos/microsoftLogo.vue';
import FacebookLogo from '../logos/facebookLogo.vue';
import AppleLogo from '../logos/appleLogo.vue';
import GithubLogo from '../logos/githubLogo.vue';
import GitlabLogo from '../logos/gitlabLogo.vue';

export default {
  name: 'HostedScreen',

  components: {
    LoginFormErrorMessage, PasswordResetDisplay, VerifyEmail, BLink, BFormInput, BFormGroup, ActivityButton,
    AuthressLogo, GoogleLogo, ZohoLogo, MicrosoftLogo, GitlabLogo, GithubLogo, FacebookLogo, AppleLogo
  },

  props: {
    domainConfiguration: {
      type: Object,
      required: false,
      default() { return {}; }
    }
  },

  data() {
    return {
      host: this.$store.getters.host,

      signup: false,
      showPasswordResetScreen: false,
      showFallbackHandling: null,
      emailIsRequired: false,
      emailInput: null,
      passwordInput: null,
      lastSelectedConnectionId: this.$store.state.cache?.lastSelectedConnection,
      apiError: null,
      showValidations: false
    };
  },

  watch: {
    emailInput() {
      this.showValidations = false;
    }
  },

  computed: {
    brandColors() {
      return this.domainConfiguration?.cssConfiguration?.brandColors || {};
    },

    showVerifyEmailScreen() {
      return this.$route.query.flow === 'verify';
    },

    /**
     * The Google connection will not work from inside mobile apps with good reason (they are insecure). So instead we block using it here.
     * https://www.reddit.com/r/facebook/comments/1684jgm/is_the_open_links_in_external_browser_function/
     */
    googleConnectionCanBeEnabled() {
      const userAgent = (navigator?.userAgentData || navigator?.userAgent || navigator?.vendor || navigator?.opera);
      if (typeof userAgent === 'string'
        && (userAgent?.includes('FBAN') || userAgent?.includes('FBAV') || userAgent?.includes('FB_IAP')
        // https://explore.whatismybrowser.com/useragents/explore/software_name/linkedin-app/
        || userAgent?.includes('LinkedInApp'))) {
        return false;
      }

      return true;
    },

    /**
     * The Apple connection must come first when the device is an Apple device, so figure that out and specify here it here.
     */
    isAppleDevice() {
      const userAgent = (navigator?.userAgentData || navigator?.userAgent || navigator?.vendor || navigator?.opera);

      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      // iPad on iOS 13 detection
      || (typeof userAgent === 'string' && userAgent.includes('Mac') && typeof document?.ontouchend !== 'undefined');
    },
    sortedConnectionList() {
      const appleConnection = { connectionId: 'con_apple', providerLogoComponent: 'apple-logo' };
      const sortedConnectionList = [
        { connectionId: 'google', providerLogoComponent: 'google-logo' },
        { connectionId: 'microsoft', providerLogoComponent: 'microsoft-logo' },
        { connectionId: 'zoho', providerLogoComponent: 'zoho-logo' },
        { connectionId: 'authress', providerLogoComponent: 'authress-logo' },
        { connectionId: 'gitlab', providerLogoComponent: 'gitlab-logo' },
        { connectionId: 'github', providerLogoComponent: 'github-logo' },
        { connectionId: 'con_facebook', providerLogoComponent: 'facebook-logo' }
      ];

      if (this.isAppleDevice) {
        return [appleConnection].concat(sortedConnectionList);
      }

      return sortedConnectionList.concat(appleConnection);
    },

    lastSelectedConnection() {
      if (this.signup) {
        return null;
      }

      if (!this.availableConnections.find(c => c.connectionId === this.lastSelectedConnectionId)) {
        return null;
      }

      return {
        connectionId: this.lastSelectedConnectionId,
        providerLogoComponent: this.sortedConnectionList.find(c => c.connectionId === this.lastSelectedConnectionId).providerLogoComponent
      };
    },
    emailSsoInputBoxEnabled() {
      return typeof this.domainConfiguration?.sso?.enabled !== 'undefined' ? this.domainConfiguration?.sso?.enabled : true;
    },
    
    availableConnections() {
      let allowedConnectionsMap = {
        google: true,
        microsoft: true,
        github: true
      };

      if (this.domainConfiguration?.connections) {
        allowedConnectionsMap = this.domainConfiguration.connections.reduce((acc, c) => ({ ...acc, [c.connectionId]: true }), {});
      }

      if (!this.googleConnectionCanBeEnabled) {
        delete allowedConnectionsMap.google;
      }
      
      return this.sortedConnectionList.filter(c => c && allowedConnectionsMap[c.connectionId]);
    },
    connectionsToDisplay() {
      return this.availableConnections.filter(c => c.connectionId !== this.lastSelectedConnection?.connectionId);
    },
    supportUrl() {
      return this.domainConfiguration?.supportUrl || `mailto:support@${this.host}`;
    },
    authenticationRequestId() {
      return this.$route.query.state;
    },

    hasErrors() {
      return Object.values(this.validations).some(v => !v && v !== undefined);
    },
    displayErrors() {
      return !this.showValidations ? null : !this.hasErrors;
    },
    disableNextButton() {
      return this.hasErrors && this.apiError !== 'SsoConnectionError';
    },
    validations() {
      if (!this.showValidations) {
        return {};
      }

      return {
        authenticationRequestId: !!this.authenticationRequestId,
        emailInput: this.emailIsRequired ? !!this.emailInput : undefined,
        passwordInput: this.showFallbackHandling === 'Password' ? !!this.passwordInput?.match(/^.{1,}$/) : undefined,
        passwordIsLongEnough: this.showFallbackHandling === 'Password' ? !!this.passwordInput?.match(/^.{20,72}$/) : undefined,
        apiError: !this.apiError
      };
    }
  },

  created() {},

  methods: {
    domainChanged() {
      this.apiError = null;
    },
    clickNextButton() {
      this.$refs.nextButton.onClick();
    },

    async validateDomainAndRedirectToLogin(connectionId) {
      this.emailIsRequired = !connectionId;
      this.showValidations = true;
      this.apiError = this.authenticationRequestId ? null : 'MissingRequiredProperty';
      // If we are displaying the SSO Email SSO Box, no problem, we'll just show the error here
      if (this.hasErrors && this.emailSsoInputBoxEnabled) {
        logger.warn({ title: '[LoginForm] Displaying an error to the user, they hit some sort of issue.', validations: this.validations, apiError: this.apiError, connectionId });
        return;
      }

      // Otherwise there is no currently location to show an error, so we can only return to the app
      if (this.hasErrors) {
        logger.warn({ title: '[LoginForm] Force returning the user to the app because there is either an error or something else blocking login and we do not have the ability to tell the user about it, unlikely that they would even care.', validations: this.validations, apiError: this.apiError, connectionId });
        await this.returnToApp();
        return;
      }

      try {
        const parameters = { source: 'HOSTED_LOGIN' };
        if (connectionId) {
          parameters.connectionId = connectionId;
          parameters.antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ connectionId });
        } else if (this.passwordInput) {
          parameters.email = this.emailInput;
          parameters.password = this.passwordInput;
          parameters.code = 'password';
          parameters.loginType = 'Password';
          parameters.connectionId = 'password';
          parameters.antiAbuseHash = await jwtManager.calculateAntiAbuseHash(parameters, { requiresExtendedMatch: true });
        } else {
          parameters.tenantLookupIdentifier = this.emailInput;
          parameters.antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ connectionId, tenantLookupIdentifier: this.emailInput });
        }

        let updatedAuthenticationRequest;
        try {
          updatedAuthenticationRequest = await new HttpClient().patch(`/authentication/${this.authenticationRequestId}`, parameters);
          this.$store.commit('setLastSelectedConnection', connectionId);
        } catch (error) {
          this.lastSelectedConnectionId = connectionId;
          if (error.data?.errorCode !== 'InvalidConnection') {
            logger.warn({ title: '[LoginForm] Failed to log user in due to an error PATCH /authentication/{authRequestId}', error, parameters });
            throw error;
          }

          logger.warn({ title: '[LoginForm] Failed to log user in due to an InvalidConnection error PATCH /authentication/{authRequestId}', error, parameters });
          throw error;
        }

        setTimeout(() => {
          window.location.assign(updatedAuthenticationRequest.data.authenticationUrl);
        }, 10);
        // Force the spinner to stay here for 5 more seconds.
        await new Promise(resolve => setTimeout(resolve, 5000));
        return;
      } catch (error) {
        if (error.data?.errorCode === 'InvalidConnection') {
          this.apiError = 'InvalidConnection';
          this.$store.commit('setLastSelectedConnection', null);
          return;
        }
        if (error.data?.errorCode === 'InvalidUserProvidedInput') {
          this.apiError = 'InvalidUserProvidedInput';
          return;
        }
        if (error.data?.errorCode === 'ContinueWithFallback') {
          this.showFallbackHandling = error.data.recommendedFallbackType;
          return;
        }
        if (error.data?.errorCode === 'InvalidTenantIdentifier') {
          this.apiError = 'InvalidTenantIdentifier';
          return;
        }
        if (error.data?.errorCode === 'InvalidConnectionConfiguration') {
          this.apiError = 'InvalidConnectionConfiguration';
          return;
        }
        if (error.data?.errorCode === 'InvalidLoginRequest') {
          await this.returnToApp();
          return;
        }
        logger.error({ title: 'Unexpected Error found when attempting to validate domain, showing an SSO connection error, but really the problem is something else. Investigate.', error });
        this.apiError = 'SsoConnectionError';
      }
    },

    async returnToApp() {
      this.showValidations = false;
      this.apiError = null;
      if (this.authenticationRequestId) {
        try {
          const authenticationRequest = await new HttpClient().get(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`);
          if (authenticationRequest.data.redirectUrl) {
            logger.log({ title: 'Redirecting to app location via redirect', appLocation: authenticationRequest.data.redirectUrl });
            window.location.replace(authenticationRequest.data.redirectUrl);
            return;
          }
        } catch (error) {
          if (error.status === 400 && error.data?.errorCode === 'ExpiredRequest') {
            logger.log({ title: 'Redirecting to app location via redirect from expired request', appLocation: error.data.redirectUrl });
            if (error.data.redirectUrl) {
              window.location.replace(error.data.redirectUrl);
              return;
            }
          }
          logger.error({ title: 'Retry navigate back to app failed', error, loginResponse: this.authResponse }, false);
        }
      }

      const appLocation = window.location.origin.replace(window.location.hostname, this.host);
      logger.log({ title: 'Redirecting to app location', appLocation });
      window.location.replace(appLocation);
    }
  }
};
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.sign-in-button {
  height: 60px !important;
  &:not(.sso) {
    width: 60px !important;
  }
  &.sso {
    padding: 10px;
  }
  min-width: 21px;
  background-color: white;
  box-shadow: 0 2px 5px var(--brand-color--bg-shadow);
  color: var(--dark);

  img {
    border-radius: 4px;
  }

  div {
    height: 21px !important;
  }
  span {
    align-self: end;
  }

  border-radius: 5px;
  border-color: var(--info);

  @media screen and (min-width: 576px) {
    padding: 10px 8px;
  }

  @media screen and (max-width: 575.98px) {
    padding: 2px 8px;
  }

  font-family: 'Roboto', sans-serif;
  line-height: normal;

  &:focus, &:active:focus {
    box-shadow: none;
  }
  &:hover:not(:disabled) {
    background-color: var(--brand-color--bg);
    color: white
  }

  &:active:not(:disabled) {
    background-color: var(--brand-color--bg);
    color: var(--light);
    border-color: var(--brand-color--bg);
  }
}

.password-input::placeholder, .email-input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: #D3D3D3;
}

::v-deep .form-group legend {
  padding-bottom: 0;
}

.password-input {
  margin-top: 0.5rem;
}

.sign-in-with {
  font-size: 10px;
  padding: 0 0.5rem;
}
.sign-in-with-line {
  display: inline-block;
  content: "";
  height: 1px;
  background-color: var(--brand-color--accent)
}

.support-link {
  color: var(--brand-color--accent)
}
</style>
